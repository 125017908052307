/**
 * @file
 *
 * This file contains data fetching code for user
 */
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { pick } from 'lodash-es';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createStore } from '@halka/state';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import { getServiceInstance } from '../service';
import { useNotifyError } from '../hooks/useNotifyError';
import { CONFIG } from '../config';
import { API_ERROR_TYPES, COOKIES } from '../constants';

export const useTenantState = createStore(null);
export const useUser = createStore(null);

const whoAmIFetcher = ({ queryKey }) => {
  const [endpoint] = queryKey;

  return getServiceInstance().get(endpoint);
};

/**
 * custom fetcher to get user details
 */
export function useLoggedInUser() {
  const { data, error } = useQuery({ queryKey: '/whoAmI', queryFn: whoAmIFetcher });
  useNotifyError({ error, mustRedirect: true });

  const history = useHistory();

  const user = useMemo(() => {
    let _user = {
      tenants: [],
    };

    if (data) {
      _user = pick(
        data,
        'first_name',
        'last_name',
        'picture',
        'has_onboarded_directly',
        'email',
        'user_id'
      );

      if (CONFIG.ENV === 'production') {
        Sentry.setUser({ id: _user.user_id });
      }

      _user.tenants =
        data?.tenants?.filter((tenant) =>
          tenant.permissions.some(({ app_code }) => app_code === CONFIG.APP_NAME)
        ) ?? [];

      _user.dataflowOnboarded = data?.additional_info?.onboarded?.dataflow;

      if (Cookies.get(COOKIES.SSO_AUTH_TOKEN)) {
        _user.isLoggedInUsingSSO = true;
      } else {
        _user.isLoggedInUsingSSO = false;
      }
    }

    return _user;
  }, [data]);

  useEffect(() => {
    useUser.set(user);
  }, [user]);

  const { pathname } = useLocation();
  const params = useParams() ?? {};
  const { tenantId } = params;

  useEffect(() => {
    if (data) {
      const prevTenant = useTenantState.get();

      if (tenantId) {
        if (prevTenant?.tenant_id !== tenantId) {
          const tenant = user.tenants.find((t) => t.tenant_id === tenantId);

          if (tenant) {
            useTenantState.set(tenant);

            if (CONFIG.ENV === 'production') {
              Sentry.setContext('tenant', tenant);
            }
          } else {
            if (pathname !== '/error') {
              history.push('/error', {
                errorType: API_ERROR_TYPES.UNAUTHORIZED,
              });
            }
          }
        }
      } else {
        if (user.tenants.length > 0) {
          const tenant = user.tenants[0];

          if (pathname === '/' || pathname === '') {
            history.push(`/t/${tenant.tenant_id}`);
          }
        } else {
          if (pathname !== '/error') {
            history.push('/error', {
              errorType: API_ERROR_TYPES.UNAUTHORIZED,
            });
          }
        }
      }
    }
  }, [pathname, tenantId, user, data, history]);

  return {
    isUserLoading: !error && !data,
    user,
  };
}
