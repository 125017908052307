/**
 * @file
 *
 * The component that renders the query summary in report view
 */
import { Alert, Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import { useQueryUrl, useTranslatedQueryUrl } from '../../state/reportView';
import { embeddEffectiveRange } from 'odata/reportView';
import { useConnection } from 'data/connectionsAndSystems';
import { SYSTEMS } from '../../constants';
import { constructURL, extractEntityName, getJobsInProgress } from 'utils';
import BookmarkButton from './BookmarkButton';
import ExpandableTypography from 'components/ExpandableTypography';
import { FETCH_STATE } from './constants';
import DialogWrapper from 'components/DialogWrapper';
import { useDisclosure } from 'hooks/useDisclosure';
import { CONFIG } from '../../config';

export default function QuerySummary({
  reportViewBookmarks,
  isLoadingData,
  openCreateBookmarkDialog,
  openExportJobDialog,
  isCreatingJob,
  batchState,
  queryData,
  showFetchLimitAlert,
  setShowFetchLimitAlert,
  allJobsInfo,
  openDrawer,
  updateDrawerTabIndex,
}) {
  const params = useParams();
  const { selectedConnection: connection } = useConnection(params.connectionId);
  const isODataSystem = connection.user_system.system.startsWith(SYSTEMS.ODATA_SERVICE);
  const queryUrl = useQueryUrl();
  const translatedQueryUrl = useTranslatedQueryUrl();

  const queryUrlObj = constructURL(queryUrl);

  const entity = extractEntityName(queryUrlObj);

  const asOfDate = queryUrlObj?.searchParams.get('asOfDate') ?? '';
  const fromDate = queryUrlObj?.searchParams.get('fromDate') ?? '';
  const toDate = queryUrlObj?.searchParams.get('toDate') ?? '';
  const balanceAsOf = queryUrlObj?.searchParams.get('balanceAsOf') ?? '';
  const effectiveRangeQuery = embeddEffectiveRange(asOfDate, fromDate, toDate, balanceAsOf);

  const whereQuery = queryUrlObj?.searchParams.get('$filter') ?? '';
  const selectQuery = joinString(queryUrlObj?.searchParams.get('$select')) ?? '';
  const expandQuery = joinString(queryUrlObj?.searchParams.get('$expand')) ?? '';
  const orderByQuery = joinString(queryUrlObj?.searchParams.get('$orderby')) ?? '';

  const [isExportDialogOpening, setIsExportDialogOpening] = useState(false);

  const { refetch: refetchJobs } = allJobsInfo;

  const {
    isOpen: isDuplicateJobWarningDlgOpen,
    close: closeDuplicateJobWarningDlg,
    open: openDuplicateJobWarningDlg,
  } = useDisclosure();

  const {
    isOpen: isConcurrentJobsWarningDlgOpen,
    close: closeConcurrentJobsWarningDlg,
    open: openConcurrentJobsWarningDlg,
  } = useDisclosure();

  const handleExportBtnClick = useCallback(async () => {
    setIsExportDialogOpening(true);

    const allJobs = await refetchJobs();
    const jobsInProgress = getJobsInProgress(allJobs.data);

    if (jobsInProgress?.length === Number(CONFIG.CONCURRENT_JOBS_LIMIT)) {
      openConcurrentJobsWarningDlg();
      setIsExportDialogOpening(false);
      return;
    }

    // check if there is already an entity whose export job is in-progress
    const entityAlreadyInProgress = jobsInProgress.some((job) => {
      const queryUrlObj = constructURL(job.additional_info.query_string);
      const entityFromJob = extractEntityName(queryUrlObj);

      return entityFromJob === entity;
    });

    if (entityAlreadyInProgress) {
      openDuplicateJobWarningDlg();
      setIsExportDialogOpening(false);

      return;
    }

    openExportJobDialog(translatedQueryUrl);
    setIsExportDialogOpening(false);
  }, [
    entity,
    openConcurrentJobsWarningDlg,
    openDuplicateJobWarningDlg,
    openExportJobDialog,
    refetchJobs,
    translatedQueryUrl,
  ]);

  // primary button action for duplicate job warning dialog
  const handlePrimaryBtnAction = useCallback(() => {
    openDrawer();
    updateDrawerTabIndex(null, 2);
    closeDuplicateJobWarningDlg();
  }, [closeDuplicateJobWarningDlg, openDrawer, updateDrawerTabIndex]);

  return (
    <>
      <Stack width="100%" direction="row" px={2} py={1}>
        <Stack
          direction="row"
          p={1}
          pl={2}
          borderRadius={1}
          sx={{ backgroundColor: '#ededed' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          width="100%"
        >
          <ExpandableTypography>
            <Typography
              component="span"
              display="inline"
              color="#9b0303"
              sx={{ backgroundColor: '#ffbfbf', borderRadius: 1, px: 1, py: 0.3 }}
              fontFamily="monospace"
            >
              entity
            </Typography>{' '}
            {entity ?? <i>Select an entity to continue</i>}{' '}
            {expandQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#034c9b"
                  sx={{ backgroundColor: '#bfdbff', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  navigations
                </Typography>{' '}
                {expandQuery};{' '}
              </>
            )}
            {effectiveRangeQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#70005b"
                  sx={{ backgroundColor: '#febfff', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  effective range
                </Typography>{' '}
                {effectiveRangeQuery};{' '}
              </>
            )}
            {whereQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#705f00"
                  sx={{ backgroundColor: '#fff2bf', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  filters
                </Typography>{' '}
                {whereQuery};{' '}
              </>
            )}
            {selectQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#9b4703"
                  sx={{ backgroundColor: '#ffe0bf', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  columns
                </Typography>{' '}
                {selectQuery};{' '}
              </>
            )}
            {orderByQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#126934"
                  sx={{ backgroundColor: '#99deb4', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  order by
                </Typography>{' '}
                {orderByQuery};
              </>
            )}
          </ExpandableTypography>
          {entity && (
            <Stack height="100%" alignItems="flex-end" justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                <BookmarkButton
                  queryUrl={queryUrl}
                  reportViewBookmarks={reportViewBookmarks}
                  isLoadingData={isLoadingData}
                  openCreateBookmarkDialog={openCreateBookmarkDialog}
                />

                <IconButton
                  onClick={handleExportBtnClick}
                  disabled={isODataSystem}
                  size="small"
                  color="primary"
                >
                  {isExportDialogOpening || isCreatingJob ? (
                    <CircularProgress size={18} />
                  ) : (
                    <BiDownload fontSize="18px" />
                  )}
                </IconButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {showFetchLimitAlert && queryData.fetchState === FETCH_STATE.FETCHED && (
        <Stack px={2} pb={1.5}>
          <Alert
            severity="warning"
            sx={{ fontSize: 13 }}
            onClose={() => setShowFetchLimitAlert(false)}
          >
            Showing the first <strong>{Number(batchState.recordsFetched).toLocaleString()}</strong>{' '}
            out of total <strong>{Number(batchState.totalRecords).toLocaleString()}</strong>{' '}
            records. To analyse the complete data, kindly export the data.
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', fontSize: 13, ml: 1, py: 0, px: 1 }}
              color="warning"
              onClick={() => openExportJobDialog(translatedQueryUrl)}
              disabled={isCreatingJob}
              startIcon={isCreatingJob ? <CircularProgress size={16} /> : <BiDownload size={16} />}
            >
              Export Data
            </Button>
          </Alert>
        </Stack>
      )}
      {isDuplicateJobWarningDlgOpen && (
        <DialogWrapper
          isOpen={isDuplicateJobWarningDlgOpen}
          closeDialog={closeDuplicateJobWarningDlg}
          title={`Export for ${entity} in progress`}
          children={
            'The data for this entity is already being exported. Kindly wait for the existing process to complete before trying again.'
          }
          primaryBtnAction={handlePrimaryBtnAction}
          primaryBtnText="Open Jobs panel"
          secondaryBtnAction={closeDuplicateJobWarningDlg}
          size={'xs'}
        />
      )}
      {isConcurrentJobsWarningDlgOpen && (
        <DialogWrapper
          isOpen={isConcurrentJobsWarningDlgOpen}
          closeDialog={closeConcurrentJobsWarningDlg}
          title={'Reached limit for parallel exports'}
          children={
            'There are 4 data exports in progress. Kindly wait for it to complete and try again.'
          }
          primaryBtnAction={closeConcurrentJobsWarningDlg}
          primaryBtnText="Close"
          size={'xs'}
        />
      )}
    </>
  );
}

function joinString(str, merger = ', ', useAnd = true) {
  const arr = (str ?? '').split(',');

  return arr.reduce((acc, curr, index) => {
    if (index === 0) {
      return curr;
    } else if (index === arr.length - 1 && useAnd) {
      return `${acc} and ${curr}`;
    } else {
      return `${acc}${merger}${curr}`;
    }
  }, '');
}
