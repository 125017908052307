/**
 * @file
 *
 * This file contains the data fetching code for bookmarks and logs of a connection
 */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { produce } from 'immer';

import { dataflowApiBase, getServiceInstance } from '../service';
import { useTenantState } from './user';
import { useNotifyError } from '../hooks/useNotifyError';

const logAndBookmarksFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${dataflowApiBase}${endpoint}`);
};

/**
 * custom data fetch hook to get the logs for the  selected conenction details
 *
 * @param {string} connectionId
 */
export function useQueryLogs(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/log`, tenant?.tenant_id],
    queryFn: logAndBookmarksFetcher,
    enabled: Boolean(tenant),
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

/**
 * custom data fetch hook to get the bookmark for the  selected conenction details
 *
 * @param {string} connectionId
 */
export function useQueryBookmarks(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
    queryFn: logAndBookmarksFetcher,
    enabled: Boolean(tenant),
    refetchOnMount: tenant && connectionId ? 'always' : false,
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

/**
 * custom data fetch hook to get the predefined bookmarks
 *
 * @param {string} connectionId
 */
export function usePredefinedBookmarks(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/suggestions`, tenant?.tenant_id],
    queryFn: logAndBookmarksFetcher,
    enabled: Boolean(tenant),
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

const deleteBookmark = (tenant, connectionId, bookmarkId) =>
  getServiceInstance(tenant.tenant_id).delete(
    `${dataflowApiBase}/connection/${connectionId}/bookmark/${bookmarkId}`
  );
export const useDeleteBookmarkMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, connectionId, bookmarkId }) => deleteBookmark(tenant, connectionId, bookmarkId),
    {
      onMutate: async ({ tenant, connectionId, bookmarkId }) => {
        await queryClient.cancelQueries([
          `/connection/${connectionId}/bookmark`,
          tenant?.tenant_id,
        ]);

        const bookmarks = queryClient.getQueryData([
          `/connection/${connectionId}/bookmark`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
          bookmarks.filter((bookmark) => bookmark.query_bookmark_id !== bookmarkId)
        );

        return { bookmarks };
      },
      onError: (error, { tenant, connectionId }, context) => {
        queryClient.setQueryData(
          [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
          context.bookmarks
        );
      },
      onSettled: (data, error, { connectionId, tenant }) => {
        queryClient.invalidateQueries([`/connection/${connectionId}/bookmark`, tenant?.tenant_id]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to delete bookmark',
  });

  return { deleteBookmarkMutation: mutation };
};

const createBookmark = (formData, tenant, connectionId) =>
  getServiceInstance(tenant?.tenant_id).post(
    `${dataflowApiBase}/connection/${connectionId}/bookmark`,
    formData
  );
export const useCreateBookmarkMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ formData, tenant, connectionId }) => createBookmark(formData, tenant, connectionId),
    {
      onSuccess: (data, { connectionId, tenant }) => {
        const bookmarks = queryClient.getQueryData([
          `/connection/${connectionId}/bookmark`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
          [data, ...bookmarks]
        );
      },
      onSettled: (data, error, { connectionId, tenant }) => {
        queryClient.invalidateQueries([`/connection/${connectionId}/bookmark`, tenant?.tenant_id]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a bookmark',
  });

  return {
    createBookmarkMutation: mutation,
  };
};

const editBookmark = (formData, tenant, selectedBookmark) =>
  getServiceInstance(tenant?.tenant_id).put(
    `${dataflowApiBase}/connection/${selectedBookmark.connection_id}/bookmark/${selectedBookmark.query_bookmark_id}`,
    formData
  );

export const useEditBookmarkMutaton = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ formData, tenant, selectedBookmark }) => editBookmark(formData, tenant, selectedBookmark),
    {
      onSuccess: (data, { selectedBookmark, tenant, formData }) => {
        const bookmarks = queryClient.getQueryData([
          `/connection/${selectedBookmark.connection_id}/bookmark`,
          tenant?.tenant_id,
        ]);

        const updatedBookmarkIndex = bookmarks.findIndex(
          (bookmark) => bookmark.query_bookmark_id === selectedBookmark.query_bookmark_id
        );

        const updatedBookmarks = produce(bookmarks, (draft) => {
          draft[updatedBookmarkIndex].name = formData.name;
          draft[updatedBookmarkIndex].description = formData.description;
        });

        queryClient.setQueryData(
          [`/connection/${selectedBookmark.connection_id}/bookmark`, tenant?.tenant_id],
          updatedBookmarks
        );
      },
      onSettled: (data, error, { tenant, selectedBookmark }) => {
        queryClient.invalidateQueries([
          `/connection/${selectedBookmark.connection_id}/bookmark`,
          tenant?.tenant_id,
        ]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update the bookmark',
  });

  return { editBookmarkMutation: mutation };
};
