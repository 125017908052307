import { createStore } from '@halka/state';
import {
  Button,
  ButtonGroup,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { get, isEmpty, isEqual, xor } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { forwardRef, useCallback, useState } from 'react';
import { MdCancel, MdPlayArrow, MdRefresh, MdSettings, MdUndo } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { cancelAllPendingBatchedRequests } from '../../reportView/main';
import {
  reportViewStateHandlers,
  useOnReportViewStateUpdate,
  useReportViewState,
  useTranslatedQueryUrl,
  updateReportViewState,
} from '../../state/reportView';
import { initialQueryDataState } from '../ReportView';
import {
  FETCH_CANCELLATION,
  FETCH_STATE,
  ROOT_LEVEL_GROUP_NAME,
  STEPPER_FLOWS,
  SYSTEMS,
} from './constants';
import DialogWrapper from 'components/DialogWrapper';
import { useDisclosure } from 'hooks/useDisclosure';
import { constructURL, getSystemConfiguration } from 'utils';
import { useTenantState } from 'data/user';
import { useCreateExportJob } from 'hooks/useCreateExportJob';
import CommonStepperDialog from './CommonStepperDialog';
import PopoverMenu from './PopoverMenu';
import { theme } from 'theme';

const previousQueryKeyStore = createStore(null);

export const useReportViewActions = ({
  schema,
  resetPagination,
  returnToFirstPage,
  fetchPaginatedDataFromDatabase,
  resetKeyHashKeeper,
  setQueryData,
  queryData,
  isSfSystem,
  ref,
  openNonVisFieldsWarningDialog,
  setNonVisFieldsDialogInfo,
  connection,
  openSystemConfigurationDialog,
  viewPicklistValues,
  closeSystemConfigurationDialog,
  initializeDatabase,
}) => {
  const { current, fork } = useReportViewState();

  useOnReportViewStateUpdate({ isSfSystem, schema });
  const translatedQueryUrl = useTranslatedQueryUrl();

  const isStateStale = !isEqual(current, fork);
  const hasFetchFailed = queryData.fetchState === FETCH_STATE.FAILED;
  const wasFetchCanceled = queryData.fetchState === FETCH_STATE.CANCELED;
  const hasFetchCompleted = queryData.fetchState === FETCH_STATE.FETCHED;
  const isFetchingData = queryData.fetchState === FETCH_STATE.FETCHING;
  const isIdle = queryData.fetchState === FETCH_STATE.IDLE;

  // If the state is stale but the current state has some data then we should the undo button
  const showUndoButton = isStateStale && !isEmpty(current.entity.value);

  // The run button should only be enabled if the following conditions meet:
  // 1. An entity is seleted (this is mandatory)
  // 2. If the fetch failed - so that users can run the query again
  // 3. If the fetch was cancelled - so that the users can run the query again
  // 4. If the fetchState is idle - when RE is idle, the button should be enabled
  // 5. If the data is not being fetched currently, should allow the users to retrigger
  //    a query when the older query is still loading
  const isRunEnabled =
    fork.entity.value && (hasFetchFailed || wasFetchCanceled || isIdle || !isFetchingData);

  const hasAsOfDateChanged = current.effectiveRange?.asOfDate !== fork.effectiveRange?.asOfDate;
  const hasFromDateChanged = current.effectiveRange?.fromDate !== fork.effectiveRange?.fromDate;
  const hasToDateChanged = current.effectiveRange?.toDate !== fork.effectiveRange?.toDate;
  const hasEffectiveRangeChanged = hasAsOfDateChanged || hasFromDateChanged || hasToDateChanged;
  const hasEntityChanged = current.entity.value !== fork.entity.value;
  const hasExpandsChanged = xor(current.expand.value, fork.expand.value).length !== 0;
  const hasFiltersChanged = xor(current.where.value.rules, fork.where.value.rules).length !== 0;

  const shouldIntializeNewDatabase =
    hasFetchFailed ||
    wasFetchCanceled ||
    hasEntityChanged ||
    hasExpandsChanged ||
    hasFiltersChanged ||
    hasEffectiveRangeChanged ||
    hasFetchCompleted;

  const queryClient = useQueryClient();
  const tenant = useTenantState();
  const userSystemEndpoint = `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`;

  const triggerDataFetch = useCallback(
    async (forceFetch, viewPicklistValues = false) => {
      if (viewPicklistValues) {
        const userSystems = await queryClient.getQueryData([userSystemEndpoint, tenant?.tenant_id]);
        const { metadata, picklist } = getSystemConfiguration(
          userSystems,
          connection?.user_system_id
        );
        if (!picklist || !metadata) {
          openSystemConfigurationDialog();
          toast.error('Metadata or Picklist is not configured');
          return;
        }
      }
      closeSystemConfigurationDialog();

      const { fork } = useReportViewState.get();

      const url = constructURL(translatedQueryUrl);
      if (!url) {
        toast.error('Failed to execute query - invalid query');
        return;
      }

      const filteredUrl = removeInaccessibleUrlFields(
        fork,
        url,
        openNonVisFieldsWarningDialog,
        setNonVisFieldsDialogInfo
      );

      reportViewStateHandlers.updateCurrentState();

      const { current } = useReportViewState.get();

      resetPagination();
      resetKeyHashKeeper();

      if (shouldIntializeNewDatabase || forceFetch) {
        // Everytime the user clicks on the Run button and is a new database is getting
        // initialized that means it is a new query and for every new query, we need a new
        // queryKey to differentiate between two subsequent triggers.
        setQueryData((state) => {
          previousQueryKeyStore.set(state.key);

          return { ...initialQueryDataState, key: nanoid(), fetchState: FETCH_STATE.FETCHING };
        });
        await initializeDatabase(current, filteredUrl)
          .then((arePendingBatchesTerminated) => {
            const fetchState = arePendingBatchesTerminated
              ? FETCH_STATE.FETCHING
              : FETCH_STATE.FETCHED;
            setQueryData((state) => ({ ...state, fetchState }));
          })
          .catch((error) => {
            let parsedError;

            try {
              parsedError = JSON.parse(error.message);
            } catch {
              parsedError = error.message;
            }

            // If the cancellation was an initial query cancellation, then we want to set
            // the queryData set to the initialState
            if (error.message.includes(FETCH_CANCELLATION.CANCEL_QUERY_TRIGGER)) {
              setQueryData({
                ...initialQueryDataState,
                fetchState: FETCH_STATE.CANCELED,
                error: parsedError,
              });
              return;
            }

            // If the cancellation was to cancel all the pending batches then we just set the
            // fetchState to cancel while preserving the rest of the state
            if (error.message.includes(FETCH_CANCELLATION.CANCEL_ALL_BATCHES)) {
              setQueryData((state) => ({
                ...state,
                fetchState: FETCH_STATE.CANCELED,
                error: parsedError,
              }));
              return;
            }

            setQueryData((state) => {
              return {
                ...state,
                fetchState: FETCH_STATE.FAILED,
                error: parsedError,
              };
            });
          });

        return;
      }

      returnToFirstPage();
      await fetchPaginatedDataFromDatabase(0, true);
    },
    [
      queryClient,
      userSystemEndpoint,
      tenant?.tenant_id,
      connection?.user_system_id,
      closeSystemConfigurationDialog,
      translatedQueryUrl,
      openNonVisFieldsWarningDialog,
      setNonVisFieldsDialogInfo,
      resetPagination,
      resetKeyHashKeeper,
      shouldIntializeNewDatabase,
      returnToFirstPage,
      fetchPaginatedDataFromDatabase,
      openSystemConfigurationDialog,
      setQueryData,
      initializeDatabase,
    ]
  );

  ref.current = triggerDataFetch;

  return { showUndoButton, triggerDataFetch, isRunEnabled };
};

export const ActionButtons = forwardRef(
  (
    {
      schema,
      resetPagination,
      resetKeyHashKeeper,
      queryData,
      setQueryData,
      isSfSystem,
      returnToFirstPage,
      fetchPaginatedDataFromDatabase,
      disableToolbar,
      connection,
      initializeDatabase,
      viewPicklistValues,
      toggleViewPicklistCheckbox,
    },
    ref
  ) => {
    const {
      isOpen: isNonVisFieldsWarningDialogOpen,
      open: openNonVisFieldsWarningDialog,
      close: closeNonVisFieldsWarningDialog,
    } = useDisclosure();
    const [nonVisFieldsDialogInfo, setNonVisFieldsDialogInfo] = useState('');

    const [popperAnchorEle, setPopperAnchorEl] = useState(null);
    const toggleRunOptionsPopper = useCallback(
      (event) => {
        setPopperAnchorEl(popperAnchorEle ? null : event.currentTarget);
      },
      [popperAnchorEle]
    );
    const isRunOptionsPopperOpen = Boolean(popperAnchorEle);

    const {
      isOpen: isSystemConfigurationDialogOpen,
      open: openSystemConfigurationDialog,
      close: closeSystemConfigurationDialog,
    } = useDisclosure();

    const { showPicklistValues, handleShowPicklistValues } = useCreateExportJob(); //for create export job

    const { showUndoButton, triggerDataFetch, isRunEnabled } = useReportViewActions({
      schema,
      resetPagination,
      resetKeyHashKeeper,
      setQueryData,
      queryData,
      isSfSystem,
      returnToFirstPage,
      fetchPaginatedDataFromDatabase,
      ref,
      openNonVisFieldsWarningDialog,
      setNonVisFieldsDialogInfo,
      connection,
      openSystemConfigurationDialog,
      viewPicklistValues,
      closeSystemConfigurationDialog,
      initializeDatabase,
    });

    const { undoForkState, resetForkState } = reportViewStateHandlers;

    // If the data is being fetched, we want to show the loader but only till there is some data to be shown.
    // If data.length>0, then we stop the loader. We also want to show a loader if we are running a query.
    // There could be scenarios where the data returned from query is 0 and the FETCHING is still going on. In this
    // scenario we have to check whether the QUERYING was successful or not
    const isFetchingData = queryData.fetchState === FETCH_STATE.FETCHING;
    const isLoadingData = queryData.data.length === 0 && isFetchingData;

    const handleCancelRequest = useCallback(() => {
      cancelAllPendingBatchedRequests(FETCH_CANCELLATION.CANCEL_ALL_BATCHES);
      setQueryData((state) => ({ ...state, fetchState: FETCH_STATE.IDLE }));
    }, [setQueryData]);

    const triggerFetchWithoutExCodes = useCallback(() => {
      toggleViewPicklistCheckbox(false);
      triggerDataFetch(false);
    }, [toggleViewPicklistCheckbox, triggerDataFetch]);

    const handleRunBtnClick = useCallback(() => {
      triggerDataFetch(false, viewPicklistValues);
    }, [triggerDataFetch, viewPicklistValues]);

    return (
      <>
        <Stack direction="row" spacing={1} alignItems="center" ml={1}>
          <ButtonGroup
            variant="contained"
            disableElevation
            size="small"
            color={isFetchingData ? 'error' : 'primary'}
          >
            <Button
              disabled={(!isFetchingData && !isRunEnabled) || disableToolbar}
              type="submit"
              onClick={isFetchingData ? handleCancelRequest : handleRunBtnClick}
              startIcon={isFetchingData ? <MdCancel /> : <MdPlayArrow />}
            >
              {isFetchingData ? 'Cancel' : 'Run'}
            </Button>
            <Button
              onClick={toggleRunOptionsPopper}
              disabled={isFetchingData || !isRunEnabled || disableToolbar}
              style={{ minWidth: '30px' }}
              sx={{
                '&.Mui-disabled': {
                  background: isFetchingData ? theme.palette.error.dark : 'primary',
                },
              }}
            >
              <MdSettings style={{ fontSize: '14' }} />
            </Button>
            {isRunOptionsPopperOpen && (
              <PopoverMenu
                isOpen={isRunOptionsPopperOpen}
                anchorEl={popperAnchorEle}
                children={
                  <RunOptions
                    viewPicklistValues={viewPicklistValues}
                    toggleViewPicklistCheckbox={toggleViewPicklistCheckbox}
                  />
                }
                close={toggleRunOptionsPopper}
              />
            )}
          </ButtonGroup>
          {showUndoButton ? (
            <Button
              disabled={isLoadingData || disableToolbar}
              startIcon={<MdUndo />}
              onClick={undoForkState}
            >
              Undo
            </Button>
          ) : (
            <Button
              disabled={isLoadingData || disableToolbar}
              startIcon={<MdRefresh />}
              onClick={() => {
                resetForkState();
                setQueryData((state) => (state.error ? initialQueryDataState : state));
              }}
            >
              Reset
            </Button>
          )}
        </Stack>
        <DialogWrapper
          isOpen={isNonVisFieldsWarningDialogOpen}
          closeDialog={closeNonVisFieldsWarningDialog}
          title="Removed non-viewable fields"
          secondaryBtnAction={closeNonVisFieldsWarningDialog}
        >
          <Stack justifyContent="center" width="100%" alignItems="center">
            <DialogContentText>
              Property <strong>{nonVisFieldsDialogInfo}</strong> was removed from the query as it is
              marked as <strong>not visible</strong> as per SuccessFactors configuration
            </DialogContentText>
          </Stack>
        </DialogWrapper>
        <CommonStepperDialog
          connection={connection}
          isCommonStepperDialogOpen={isSystemConfigurationDialogOpen}
          closeCommonStepperDialog={closeSystemConfigurationDialog}
          finalActionBtnProps={{
            btnAction: triggerFetchWithoutExCodes,
            btnText: 'Run Query',
            btnProps: null,
          }}
          stepperFlowProps={{
            showPicklistValues: showPicklistValues,
            handleShowPicklistValues: handleShowPicklistValues,
          }}
          stepperFlow={STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY}
        />
      </>
    );
  }
);

function RunOptions({ viewPicklistValues, toggleViewPicklistCheckbox }) {
  return (
    <FormControlLabel
      control={<Checkbox checked={viewPicklistValues} onChange={toggleViewPicklistCheckbox} />}
      label="Show external codes"
    />
  );
}

function removeInaccessibleUrlFields(
  forkedState,
  url,
  openNonVisFieldsWarningDialog,
  setNonVisFieldsDialogInfo
) {
  // we need to remove 'non-visible' fields before triggering the Query.
  const value = forkedState.visibleFields.value;
  const optionsMap = forkedState.visibleFields.optionsMap;
  const selectedFields = value.map((fieldKey) => get(optionsMap, fieldKey));

  // seperate the invisible and visible fields from the selected-fields
  const { visibleFieldsArr, invisibleFieldsArr } = selectedFields.reduce(
    (acc, { visible, name }) => {
      if (visible) {
        acc.visibleFieldsArr.push(name);
        return acc;
      }

      acc.invisibleFieldsArr.push(name);
      return acc;
    },
    { visibleFieldsArr: [], invisibleFieldsArr: [] }
  );

  // set the visible fields in the url $select param
  const params = new URLSearchParams(url.search);

  if (visibleFieldsArr.length > 0) {
    params.delete('$select');
    params.set('$select', visibleFieldsArr.join(','));
  }

  url.search = params.toString();

  // If invisible fields are present, then show the warning dialog and update the query state
  if (invisibleFieldsArr.length > 0) {
    setNonVisFieldsDialogInfo(invisibleFieldsArr.join(', '));

    const visibleFieldKeys = visibleFieldsArr.map((field) =>
      field.includes('/') ? field.replace('/', '.') : `${ROOT_LEVEL_GROUP_NAME}.${field}`
    );

    openNonVisFieldsWarningDialog();

    updateReportViewState((state) => {
      state.fork.visibleFields.value = visibleFieldKeys;
    });
  }

  return url.href;
}
