import React from 'react';
import { Stack } from '@mui/material';

import { SelectEntityOption } from './SelectEntityOption';
import { FilterOption } from './FilterOption';
import { SortByOption } from './SortByOption';
import { VisibleFieldsOption } from './VisibleFieldsOption';
import { ExpandOption } from './ExpandOption';
import { ArrangeFieldsOption } from './ArrangeFieldsOption';
import { EffectiveRangeOption } from './EffectiveRangeOption';

export function ReportViewToolbar({
  schema,
  isSfSystem,
  isLoadingData,
  disableToolbar,
  hasDataLoaded,
}) {
  // TODO: Show tooltip when buttons are disabled

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <SelectEntityOption disabled={isLoadingData || disableToolbar} schema={schema} />
        <ExpandOption schema={schema} disabled={isLoadingData || disableToolbar} />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <FilterOption disabled={isLoadingData || disableToolbar} isSfSystem={isSfSystem} />
        {isSfSystem && <EffectiveRangeOption disabled={isLoadingData || disableToolbar} />}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <VisibleFieldsOption disabled={isLoadingData || disableToolbar} />
        <ArrangeFieldsOption disabled={isLoadingData || disableToolbar} />
        <SortByOption disabled={isLoadingData || disableToolbar} hasDataLoaded={hasDataLoaded} />
      </Stack>
    </>
  );
}
