/**
 * @file
 *
 * This file hoists the query builder state into a global scope so that changing between page views like visualizer and query builder doesn't wipe out the state
 *
 * Also it uses state management solutions that enable transient updates for better performance overall
 */
import { useCallback, useEffect, useState } from 'react';
import { createStore } from '@halka/state';
import { nanoid } from 'nanoid';
import { get, clamp, last, toPath, initial } from 'lodash-es';
import constate from 'constate';
import { useHistory, useLocation } from 'react-router-dom';
import sortArray from 'sort-array';

import { wrapWithImmer } from '../utils';
import { createQuery, typeBasedFilterOptions } from '../odata/queryBuilder';
import {
  checkIfMultiplicitySupportable,
  flattenEntityType,
  ENUMS,
  getOriginalEntityType,
  formQueryURL,
  getTargetEntityTypeFromExpandOption,
} from '../odata/utils';
import { tourSteps, useTourState } from '../components/Onboarding/Tour';

function useQueryUrlStore({ baseEndpoint, baseParams }) {
  const [queryUrl, updateQueryUrl] = useState(() => formQueryURL(baseEndpoint, baseParams));

  return { updateQueryUrl, queryUrl };
}

export const [QueryUrlProvider, useQueryUrl, useUpdateQueryUrl] = constate(
  useQueryUrlStore,
  (context) => context.queryUrl,
  (context) => context.updateQueryUrl
);

export const OPERATORS = {
  AND: 'and',
  OR: 'or',
};
export const PAGINATION = {
  TOP: {
    DEFAULT: 10,
    MIN: 1,
    MAX: 1000,
  },
  SKIP: {
    DEFAULT: 0,
    MIN: 0,
    MAX: Number.MAX_SAFE_INTEGER,
  },
};

const initialQueryBuilderState = {
  queryKey: nanoid(),

  tag: null,

  entity: {
    options: [],
    value: null,
  },

  top: PAGINATION.TOP.DEFAULT,
  skip: PAGINATION.SKIP.DEFAULT,

  effectiveRange: {
    asOfDate: null,
    fromDate: null,
    toDate: null,
  },

  where: {
    options: [],
    value: {
      id: nanoid(),
      operator: OPERATORS.AND,
      rules: [],
    },
  },

  expand: {
    options: [],
    value: [],
  },

  columnSelect: {
    options: [],
    value: [],
    flag: ENUMS.COLUMN_SELECT_FLAG.INCLUDE,
  },

  orderBy: {
    options: [],
    value: [],
    flags: {},
  },
};

export const useQueryBuilderState = createStore(initialQueryBuilderState);
export const updateQueryBuilderState = wrapWithImmer(useQueryBuilderState.set);

export const resetQueryBuilder = () => {
  useQueryBuilderState.set((state) => ({
    ...initialQueryBuilderState,
    entity: {
      value: null,
      options: state.entity.options,
    },
    queryKey: nanoid(),
  }));
};

const odataQueryParamKey = 'odataQuery';
export function useShareableUrl() {
  const location = useLocation();
  const history = useHistory();

  const getQueryUrlFromLocation = useCallback(
    () => {
      const url = new URL(window.location.href);

      const odataQuery = url.searchParams.get(odataQueryParamKey);
      return odataQuery && decodeURIComponent(odataQuery);
    },
    // eslint-disable-next-line
    []
  );

  const getShareableUrl = useCallback(
    (queryUrl) => {
      const baseUrl = window.location.origin + location.pathname;

      if (queryUrl) {
        const shareableUrl = new URL(baseUrl);
        shareableUrl.searchParams.set(odataQueryParamKey, encodeURIComponent(queryUrl));

        return shareableUrl.href;
      }

      return baseUrl;
    },
    // eslint-disable-next-line
    []
  );

  const purgeQueryStringFromLocation = useCallback(() => {
    history.replace(location.pathname);
  }, [history, location]);

  return {
    getQueryUrlFromLocation,
    getShareableUrl,
    purgeQueryStringFromLocation,
  };
}

export function useInitializeQueryBuilderState(schema, populateQueryBuilder) {
  const { purgeQueryStringFromLocation, getQueryUrlFromLocation } = useShareableUrl();

  useEffect(
    () => {
      if (schema) {
        updateQueryBuilderState((state) => {
          state.entity.options = schema.entityTypes.map(flattenEntityType);
        });

        setTimeout(() => {
          const queryUrl = getQueryUrlFromLocation();
          if (queryUrl) {
            populateQueryBuilder(queryUrl, true);

            purgeQueryStringFromLocation();
          }
        });
      }

      return () => {
        useQueryBuilderState.set({ ...initialQueryBuilderState, queryKey: nanoid() });
      };
    },
    // eslint-disable-next-line
    [schema]
  );
}

export const pickQueryParamsFromQueryBuilderState = (state) => ({
  entity: state.entity.value,
  top: state.top,
  skip: state.skip,
  ...state.effectiveRange,
  where: state.where.value,
  expand: state.expand.value,
  columnSelectOptions: state.columnSelect.options,
  columnSelect: state.columnSelect.value,
  columnSelectFlag: state.columnSelect.flag,
  orderBy: state.orderBy.value,
  orderByFlags: state.orderBy.flags,
});

export function useOnQueryBuilderStateUpdate({ systemType, schema, baseEndpoint, baseParams }) {
  const queryBuilderState = useQueryBuilderState();

  const updateQueryUrl = useUpdateQueryUrl();

  useEffect(() => {
    if (baseEndpoint) {
      if (schema && queryBuilderState.entity.value) {
        const queryString = createQuery({
          systemType,
          schema,
          ...pickQueryParamsFromQueryBuilderState(queryBuilderState),
        });

        updateQueryUrl(formQueryURL(baseEndpoint, baseParams, queryString));
      } else {
        updateQueryUrl(formQueryURL(baseEndpoint, baseParams));
      }
    }
  }, [queryBuilderState, schema, baseEndpoint, baseParams, systemType, updateQueryUrl]);
}

export function getSortedProperties(allProperties) {
  sortArray(allProperties, {
    by: ['isNavigationProp', 'isKey', 'filterable', 'label'],
    order: ['isNavigationProp', 'isKey', 'filterable', 'asc'],
    customOrders: {
      isNavigationProp: [false, true],
      isKey: [true, false],
      filterable: [true, false],
    },
  });
}

export function computeWhereOptions(selectedEntity, schema, isSfSystem) {
  // get the navigation properties with multiplicity 0..1
  // get their properties flattened out with parent value attached for the role
  const navigationProperties =
    selectedEntity?.navigationProperty?.reduce((navProperties, navProp) => {
      const association = schema.associationsMap[navProp.relationship];

      const toRole = association.end.find(({ role }) => role === navProp.toRole);

      const isMultiplicitySupportable = isSfSystem || checkIfMultiplicitySupportable(toRole);

      if (isMultiplicitySupportable) {
        const properties = flattenEntityType(
          schema.entityTypesMap[toRole.type],
          schema.complexTypesMap
        ).property;

        return [
          ...navProperties,
          ...properties.map((prop) => ({
            ...prop,
            name: `${navProp.name}/${prop.name}`,
            parent: navProp.name,
            isNavigationProp: true,
          })),
        ];
      }

      return navProperties;
    }, []) ?? [];

  const baseProperties = selectedEntity?.property?.map((prop) => ({
    ...prop,
    isNavigationProp: false,
  }));

  const allProperties = [...baseProperties, ...navigationProperties];

  getSortedProperties(allProperties);

  return allProperties;
}

export function computeExpandOptions(selectedEntity, schema, parent) {
  return (
    selectedEntity.navigationProperty?.map((expandableProperty) => ({
      ...expandableProperty,
      association: schema.associationsMap[expandableProperty.relationship],
      parent: parent ?? undefined,
      name: `${parent ?? ''}${parent ? ENUMS.DELIMITERS.EXPAND : ''}${expandableProperty.name}`,
    })) ?? []
  );
}

export function computeColumnSelectOptions(selectedEntity, schema, expandedValues) {
  let selectOptions = getOriginalEntityType(selectedEntity, schema.entityTypesMap)?.property ?? [];

  selectOptions = selectOptions.map((option) => ({ ...option, isNavigationProp: false }));

  if (expandedValues.length !== 0) {
    expandedValues.forEach((expandedValue) => {
      const to = expandedValue.association.end.find((end) => end.role === expandedValue.toRole);

      if (to) {
        const toEntityType = schema.entityTypesMap[to.type];

        selectOptions = selectOptions.concat(
          toEntityType?.property?.map((prop) => ({
            ...prop,
            parent: expandedValue.name,
            name: `${expandedValue.name}/${prop.name}`,
            isNavigationProp: true,
          })) ?? []
        );
      }
    });
  }

  getSortedProperties(selectOptions);

  return selectOptions;
}

export function computeOrderByOptions(selectedEntity) {
  const baseProperties = selectedEntity?.property.filter((prop) => prop.sortable) ?? [];

  getSortedProperties(baseProperties);

  return baseProperties;
}

export function useEntitySelectActions(schema, isSfSystem) {
  const onEntityChange = useCallback(
    (event, value) => {
      if (!value) {
        resetQueryBuilder();
      } else {
        updateQueryBuilderState((state) => {
          if (state.entity.value !== value) {
            state.queryKey = nanoid();

            const flattenedEntity = flattenEntityType(value, schema.complexTypesMap);

            state.entity.value = flattenedEntity;

            state.top = initialQueryBuilderState.top;
            state.skip = initialQueryBuilderState.skip;

            state.effectiveRange.asOfDate = initialQueryBuilderState.effectiveRange.asOfDate;
            state.effectiveRange.fromDate = initialQueryBuilderState.effectiveRange.fromDate;
            state.effectiveRange.toDate = initialQueryBuilderState.effectiveRange.toDate;

            state.where.options = flattenedEntity
              ? computeWhereOptions(flattenedEntity, schema, isSfSystem)
              : initialQueryBuilderState.where.options;
            state.where.value = initialQueryBuilderState.where.value;

            state.expand.options = flattenedEntity
              ? computeExpandOptions(flattenedEntity, schema)
              : initialQueryBuilderState.expand.options;
            state.expand.value = initialQueryBuilderState.expand.value;

            state.columnSelect.options = flattenedEntity
              ? computeColumnSelectOptions(
                  flattenedEntity,
                  schema,
                  initialQueryBuilderState.expand.value
                )
              : initialQueryBuilderState.columnSelect.options;
            state.columnSelect.value = initialQueryBuilderState.columnSelect.value;
            state.columnSelect.flag = ENUMS.COLUMN_SELECT_FLAG.INCLUDE;

            state.orderBy.options = flattenedEntity
              ? computeOrderByOptions(flattenedEntity)
              : initialQueryBuilderState.orderBy.options;
            state.orderBy.value = initialQueryBuilderState.orderBy.value;
            state.orderBy.flags = initialQueryBuilderState.orderBy.flags;
          }

          const { currentStepId } = useTourState.get();
          if (currentStepId === tourSteps['select-entity'].id) {
            tourSteps['select-entity'].next();
          }
        });
      }
    },
    [isSfSystem, schema]
  );

  return { onEntityChange };
}

export const useTagSelectActions = (schema) => {
  const onTagSelection = useCallback(
    (event, value) => {
      if (!value) {
        useQueryBuilderState.set(() => ({
          ...initialQueryBuilderState,
          entity: {
            value: initialQueryBuilderState.entity.value,
            options: schema.entityTypes.map(flattenEntityType),
          },
          queryKey: nanoid(),
        }));
      } else {
        useQueryBuilderState.set(() => {
          const filteredEntityOptions = schema?.entityTypes
            .filter((entity) => entity.tag.includes(value))
            .map(flattenEntityType);

          return {
            ...initialQueryBuilderState,
            tag: value,
            entity: {
              value: initialQueryBuilderState.entity.value,
              options: filteredEntityOptions,
            },
          };
        });
      }
    },
    [schema]
  );

  return { onTagSelection };
};

export const validatePaginationParam = {
  top: (_value) => {
    try {
      const value = parseInt(_value, 10);

      if (!Number.isNaN(value)) {
        return clamp(value, PAGINATION.TOP.MIN, PAGINATION.TOP.MAX);
      }
    } catch (err) {}
    return null;
  },
  skip: (_value) => {
    try {
      const value = parseInt(_value, 10);

      if (!Number.isNaN(value)) {
        return clamp(value, PAGINATION.SKIP.MIN, PAGINATION.SKIP.MAX);
      }
    } catch (err) {}

    return null;
  },
};

export const paginationActions = {
  onTopChange: (value) => {
    updateQueryBuilderState((state) => {
      state.top = value;
    });
  },
  onSkipChange: (value) => {
    updateQueryBuilderState((state) => {
      state.skip = value;
    });
  },
};

export const effectiveRangeActions = {
  onAsOfDateChange: (date) => {
    updateQueryBuilderState((state) => {
      state.effectiveRange.asOfDate = date;
      if (date) {
        state.effectiveRange.fromDate = null;
        state.effectiveRange.toDate = null;
      }
    });
  },
  onFromDateChange: (date) => {
    updateQueryBuilderState((state) => {
      state.effectiveRange.fromDate = date;
    });
  },
  onToDateChange: (date) => {
    updateQueryBuilderState((state) => {
      state.effectiveRange.toDate = date;
    });
  },
  reset: () => {
    updateQueryBuilderState((state) => {
      state.effectiveRange.asOfDate = null;
      state.effectiveRange.fromDate = null;
      state.effectiveRange.toDate = null;
    });
  },
};

export const WHERE_CONDITION = {
  BASE_PATH: 'where.value',
  TYPE: {
    GROUP: 'group',
    RULE: 'rule',
  },
};
export function useWhereActions(path, type) {
  // add new rule
  const addNewRule = useCallback(() => {
    updateQueryBuilderState((state) => {
      const defaultProperty = state.where.options.find((property) => property.filterable);

      let rules;
      if (type === WHERE_CONDITION.TYPE.GROUP) {
        rules = get(state, `${path}.rules`);
      } else {
        rules = get(state, initial(toPath(path)));
      }

      const newRule = {
        id: nanoid(),
        property: defaultProperty,
        filter: typeBasedFilterOptions[defaultProperty.type][0],
        param: '',
      };

      rules.push(newRule);
    });
  }, [path, type]);

  // update rule property
  const updateRuleProperty = useCallback(
    (property) => {
      updateQueryBuilderState((state) => {
        const rule = get(state, path);
        rule.property = property;
        rule.filter = typeBasedFilterOptions[property.type][0];
        rule.param = '';
      });
    },
    [path]
  );

  // update rule filter
  const updateRuleFilter = useCallback(
    (filter) => {
      updateQueryBuilderState((state) => {
        const rule = get(state, path);
        if (rule.filter.group !== filter.group) {
          rule.param = '';
        }

        rule.filter = filter;
      });
    },
    [path]
  );

  // update rule param
  const updateRuleParam = useCallback(
    (param) => {
      updateQueryBuilderState((state) => {
        const rule = get(state, path);
        rule.param = param;
      });
    },
    [path]
  );

  // add new group
  const addNewGroup = useCallback(() => {
    updateQueryBuilderState((state) => {
      const rules = get(state, `${path}.rules`);

      rules.push({
        id: nanoid(),
        operator: OPERATORS.AND,
        rules: [],
      });
    });
  }, [path]);

  // change operator
  const toggleGroupOperator = useCallback(() => {
    updateQueryBuilderState((state) => {
      const group = get(state, path);
      group.operator = group.operator === OPERATORS.AND ? OPERATORS.OR : OPERATORS.AND;
    });
  }, [path]);

  // delete group
  const deleteRuleOrGroup = useCallback(() => {
    updateQueryBuilderState((state) => {
      const _path = toPath(path);

      const rules = get(state, initial(_path));

      rules.splice(Number(last(_path)), 1);
    });
  }, [path]);

  return {
    addNewRule,
    updateRuleParam,
    updateRuleFilter,
    updateRuleProperty,
    addNewGroup,
    toggleGroupOperator,
    deleteRuleOrGroup,
  };
}

export const SELECTED_OPTIONS_CHANGE_REASONS = {
  ADD: 'select-option',
  REMOVE: 'remove-option',
  MUI5_ADD: 'selectOption',
  MUI5_REMOVE: 'removeOption',
  MUI5_CLEAR: 'clear',
};

export function useExpandActions(schema) {
  const onSelectedExpandChange = useCallback(
    (event, values, reason) => {
      updateQueryBuilderState((state) => {
        // if all values are cleared
        if (values.length === 0) {
          state.expand.options = computeExpandOptions(state.entity.value, schema);
          state.expand.value = [];

          // cleanup related columnSelect state
          const columnSelectOptions = computeColumnSelectOptions(state.entity.value, schema, []);
          state.columnSelect.options = columnSelectOptions;
          const columnSelectOptionNames = columnSelectOptions.map((option) => option.name);
          state.columnSelect.value = state.columnSelect.value.filter((value) =>
            columnSelectOptionNames.includes(value.name)
          );

          return;
        }

        // when a new option is selected for expand
        if (reason === SELECTED_OPTIONS_CHANGE_REASONS.ADD) {
          // get the latest selected value (which will be the last one)
          const newlyAdded = last(values);

          // get the toRole entity type because next level of expand will depend on that
          const entityType = getTargetEntityTypeFromExpandOption(newlyAdded, schema);

          // new options added for the next level in the options
          const newOptions = computeExpandOptions(entityType, schema, newlyAdded.name);

          // push the new options
          state.expand.options = state.expand.options.concat(newOptions);

          // update the expand value
          state.expand.value = values;

          // depending on the expanded values we also add to the column select options
          state.columnSelect.options = computeColumnSelectOptions(
            state.entity.value,
            schema,
            values
          );
        } else if (reason === SELECTED_OPTIONS_CHANGE_REASONS.REMOVE) {
          // when an options is remove from expand
          // grab the removed value by comparing the previous state of expanded
          // with the new expanded values from the change event
          const removedValueIndex = state.expand.value
            .map(({ name }) => name)
            .findIndex((name) => !values.map(({ name: _name }) => _name).includes(name));
          const removedValue = state.expand.value[removedValueIndex];

          // a predicate function that clears the available options list and
          // the expanded option list of all the hanging descendants
          // (whose parent is not expanded/expandable anymore)
          const purgeZombiesPredicate = (value) =>
            value.parent === undefined || !value.parent.startsWith(removedValue.name);

          // update the expand options
          state.expand.options = state.expand.options.filter(purgeZombiesPredicate);
          // update the expand values
          const expandedValues = values.filter(purgeZombiesPredicate);
          state.expand.value = expandedValues;

          // !! this part is tricky, the column select options and hence the values depend on the expanded columns
          // because we allow projecting select into the expanded entities as well
          const columnSelectOptions = computeColumnSelectOptions(
            state.entity.value,
            schema,
            expandedValues
          );
          state.columnSelect.options = columnSelectOptions;
          const columnSelectOptionNames = columnSelectOptions.map((option) => option.name);
          state.columnSelect.value = state.columnSelect.value.filter((value) =>
            columnSelectOptionNames.includes(value.name)
          );
        }
      });
    },
    [schema]
  );

  return {
    onSelectedExpandChange,
  };
}

export const columnSelectActions = {
  toggleSelectFlag: () => {
    updateQueryBuilderState((state) => {
      state.columnSelect.flag =
        state.columnSelect.flag === ENUMS.COLUMN_SELECT_FLAG.EXCLUDE
          ? ENUMS.COLUMN_SELECT_FLAG.INCLUDE
          : ENUMS.COLUMN_SELECT_FLAG.EXCLUDE;
    });
  },
  onColumnSelectChange: (event, value) => {
    updateQueryBuilderState((state) => {
      state.columnSelect.value = value;
    });
  },
};

export const orderByActions = {
  onOrderByChange: (event, value, reason) => {
    updateQueryBuilderState((state) => {
      state.orderBy.value = value;

      if (reason === SELECTED_OPTIONS_CHANGE_REASONS.ADD) {
        value.forEach((_value) => {
          if (!state.orderBy.flags[_value.name]) {
            state.orderBy.flags[_value.name] = ENUMS.ORDER_BY_FLAG.ASC;
          }
        });
      }
    });
  },
  onToggleOrderByFlag: (columnName) => {
    updateQueryBuilderState((state) => {
      state.orderBy.flags[columnName] =
        state.orderBy.flags[columnName] === ENUMS.ORDER_BY_FLAG.ASC
          ? ENUMS.ORDER_BY_FLAG.DESC
          : ENUMS.ORDER_BY_FLAG.ASC;
    });
  },
  onOrderByRemove: (index) => {
    updateQueryBuilderState((state) => {
      state.orderBy.value.splice(index, 1);
    });
  },
};
