/**
 * @file
 *
 * this file contains the component that renders the top nav bar section of the app
 */
import React from 'react';
import {
  Grid,
  AppBar,
  IconButton,
  makeStyles,
  Avatar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Link,
  Tooltip,
  Divider,
  useTheme,
  DialogContentText,
  Chip,
} from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { useLocation, useHistory } from 'react-router-dom';
import {
  MdCheck,
  MdError,
  MdExitToApp,
  MdHelpOutline,
  MdHome,
  MdInsertChart,
  MdMailOutline,
  MdMessage,
  MdPersonOutline,
} from 'react-icons/md';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import clsx from 'clsx';

import { useLoggedInUser, useTenantState } from '../data/user';
import { getPlatformURL, getAssetURL, getServiceInstance, redirectToLoginPage } from '../service';
import { tourSteps } from './Onboarding/Tour';
import { QuotaUsageDisplayDialog } from '../components/QuoteUsageDisplayDialog';
import { billingPlanDisplayActions, useQuotaUsageState } from '../data/billingUsage';
import { useConfirmationDialog } from 'material-ui-confirmation';
import { getMailToString } from 'utils';
import { EMAIL_FORMATS } from '../constants';
import { useCurrentTabId } from './TabIndexedStorageContext';
import { purgeIDB } from 'reportView/worker';

const useStyles = makeStyles((theme) => ({
  nav: {
    height: theme.spacing(5.5),
    color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.navBar,
  },
  logo: {
    height: theme.spacing(4),
    width: 'auto',
    marginTop: theme.spacing(1.2),
  },
  stretchHeight: {
    height: '100%',
  },
  avatar: {
    background: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  hidden: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
  title: {
    textAlign: 'center',
  },
  tenantBtn: {
    textTransform: 'initial',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  checkmark: {
    marginLeft: theme.spacing(1),
  },
  username: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  menuTriggerButton: {
    textTransform: 'none',
  },
  reportIssueOption: {
    color: theme.palette.error.main,
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(0.13),
    height: theme.spacing(2.6),
    opacity: '70%',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  optionIcons: {
    color: theme.palette.text.secondary,
  },
  switchThemeBtn: {
    color: theme.palette.common.white,
  },
  ssoChip: {
    marginLeft: theme.spacing(1),
    borderRadius: 4,
  },
  ssoChipLight: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderRadius: 4,
  },
}));

const getNormalizedTenantName = (tenant) =>
  tenant.has_onboarded_directly ? 'Default Tenant' : tenant.name;

export function Nav() {
  const classes = useStyles();
  const theme = useTheme();

  const tabId = useCurrentTabId();

  const { user, isUserLoading } = useLoggedInUser();
  const tenant = useTenantState();

  const { pathname } = useLocation();
  const history = useHistory();

  const platformUrl = `${getPlatformURL()}/#/launchpad/${
    tenant?.tenant_id ? `t-${tenant.tenant_id}-t` : ''
  }`;

  const { isDialogOpen } = useQuotaUsageState();

  const { getConfirmation } = useConfirmationDialog();

  const handleLogout = (popupState, redirectURL) => async () => {
    try {
      await getServiceInstance().post('/logout', {}, { withCredentials: true });

      if (popupState) {
        popupState.close();
      }

      await purgeIDB({ tabId });
      redirectToLoginPage(redirectURL);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleTenantSwitch = (popupState, tenant) => () => {
    if (user.isLoggedInUsingSSO === tenant.sso_enabled) {
      popupState.close();
      history.push(`/t/${tenant.tenant_id}`);
    } else {
      popupState.close();

      getConfirmation({
        title: 'Switch Tenant',
        body: (
          <DialogContentText>
            You are about to switch to a tenant which may require you to enter your login
            credentials. This action will log you out from the current tenant.
            <br />
            <br />
            <strong>Are you sure you want to proceed with the redirection?</strong>
          </DialogContentText>
        ),

        onAccept: () => {
          handleLogout(null, `${window.location.origin}/t/${tenant.tenant_id}`)();
        },

        acceptButtonProps: {
          disableElevation: true,
          variant: 'contained',
        },
      });
    }
  };

  return (
    <>
      <Grid container component={AppBar} className={classes.nav} justifyContent="center">
        <Grid item xs={4}>
          <Grid item container alignItems="center" justifyContent="flex-start" spacing={2}>
            <>
              <Grid item>
                <IconButton component={Link} size="small" href={platformUrl}>
                  <MdHome color={common.white} />
                </IconButton>
              </Grid>
              <Grid item component={Link} href={platformUrl}>
                <img
                  src={getAssetURL('/logos/integrtr_logo_light.png')}
                  className={classes.logo}
                  alt="INTEGRTR"
                />
              </Grid>
            </>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.title}>
          {Boolean(tenant) && user.tenants.length > 1 && (
            <PopupState variant="popover" popupId="tenant-switch-menu">
              {(popupState) => (
                <>
                  <Button
                    variant="outlined"
                    color="default"
                    size="small"
                    disableElevation
                    className={classes.tenantBtn}
                    {...bindTrigger(popupState)}
                  >
                    {getNormalizedTenantName(tenant)}
                  </Button>
                  <Menu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    {...bindMenu(popupState)}
                    getContentAnchorEl={null}
                  >
                    {user.tenants.map((_tenant) => (
                      <MenuItem
                        key={_tenant.tenant_id}
                        onClick={handleTenantSwitch(popupState, _tenant)}
                        disabled={tenant.tenant_id === _tenant.tenant_id}
                      >
                        {getNormalizedTenantName(_tenant)}
                        {_tenant.sso_enabled && (
                          <Chip
                            size="small"
                            variant="outlined"
                            label="SSO"
                            className={classes.ssoChip}
                          />
                        )}
                        {tenant.tenant_id === _tenant.tenant_id && (
                          <MdCheck size={18} className={classes.checkmark} />
                        )}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </PopupState>
          )}
          {Boolean(tenant) && user.tenants.length === 1 && (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Typography>{getNormalizedTenantName(user.tenants[0])}</Typography>
              {user.tenants[0].sso_enabled && (
                <Chip
                  size="small"
                  variant="outlined"
                  label="SSO"
                  className={classes.ssoChipLight}
                />
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={5}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
            {/* <Grid item>
              <Tooltip title="Switch theme">
                <IconButton onClick={switchTheme} className={classes.switchThemeBtn}>
                  <CgDarkMode />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item style={{ paddingTop: theme.spacing(1), marginLeft: theme.spacing(0.5) }}>
              <Tooltip title="Contact Us">
                <Link
                  href={getMailToString({
                    emailSubject: EMAIL_FORMATS.SUPPORT.subject,
                    emailBody: EMAIL_FORMATS.SUPPORT.getBody(user, tenant),
                  })}
                  size="small"
                >
                  <MdMailOutline color="white" fontSize={20} />
                </Link>
              </Tooltip>
            </Grid>
            <Grid
              item
              style={{
                paddingTop: theme.spacing(1),
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(0.7),
              }}
            >
              <Tooltip title="Help">
                <Link
                  href="https://help.integrtr.com/dataflow--9fb8f7c2-d762-4f71-854a-03015dae2bfb"
                  size="small"
                  target="_blank"
                  data-tour-step={tourSteps['help-button'].id}
                >
                  <MdHelpOutline color="white" fontSize={20} />
                </Link>
              </Tooltip>
            </Grid>

            <Divider className={classes.divider} orientation="vertical" />
            <Grid item>
              <PopupState variant="popover" popupId="user-menu">
                {(popupState) => (
                  <>
                    <Button
                      classes={{ root: classes.menuTriggerButton }}
                      {...bindTrigger(popupState)}
                    >
                      <Typography className={classes.username}>
                        {isUserLoading ? '...' : user?.first_name ?? '...'}
                      </Typography>
                      <Avatar className={classes.avatar} src={user?.picture}>
                        <MdPersonOutline color={common.white} />
                      </Avatar>
                    </Button>
                    <Menu
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      {...bindMenu(popupState)}
                      getContentAnchorEl={null}
                    >
                      <MenuItem
                        disabled={pathname === '/error'}
                        onClick={() => {
                          billingPlanDisplayActions.openDialog();
                          popupState.close();
                        }}
                      >
                        <MdInsertChart
                          className={clsx(classes.optionIcons, classes.rightMargin)}
                          fontSize={20}
                        />
                        View Usage
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        href={getMailToString({
                          emailSubject: EMAIL_FORMATS.FEEDBACK.subject,
                          emailBody: EMAIL_FORMATS.FEEDBACK.getBody(user, tenant),
                        })}
                        onClick={popupState.close}
                        color="textPrimary"
                      >
                        <MdMessage
                          className={clsx(classes.optionIcons, classes.rightMargin)}
                          fontSize={20}
                        />
                        Share Feedback
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        href={getMailToString({
                          emailSubject: EMAIL_FORMATS.BUG.subject,
                          emailBody: EMAIL_FORMATS.BUG.getBody(user, tenant),
                        })}
                        className={classes.reportIssueOption}
                        onClick={popupState.close}
                      >
                        <MdError className={classes.rightMargin} fontSize={20} />
                        Report Issue
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={handleLogout(popupState, window.location.origin)}>
                        <MdExitToApp
                          className={clsx(classes.optionIcons, classes.rightMargin)}
                          fontSize={20}
                        />
                        Log out
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <QuotaUsageDisplayDialog
        isOpen={isDialogOpen}
        close={billingPlanDisplayActions.closeDialog}
      />
    </>
  );
}
