/**
 * @file
 *
 * This component displays the query url and actions related to it like copy to clipboard and bookmarking it
 */
import { unstable_batchedUpdates } from 'react-dom';
import React, { useCallback, useState } from 'react';
import {
  Grid,
  Card,
  Tooltip,
  Typography,
  IconButton,
  Link,
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { MdContentCopy, MdBookmarkBorder, MdBookmark, MdShare, MdModeEdit } from 'react-icons/md';

import { useQueryBookmarks } from '../data/queriesAndBookmarks';
import { formQueryURL } from '../odata/utils';
import {
  useOnQueryBuilderStateUpdate,
  useQueryBuilderState,
  useQueryUrl,
  useShareableUrl,
} from '../state/queryBuilder';
import {
  checkBookmarkExists,
  getBillingExpiryStatus,
  getTooltipTitleForPlanRelatedFeature,
} from '../utils';
import { useCopy } from '../hooks/useCopy';
import { tourSteps, useTourState } from './Onboarding/Tour';
import { commonTextFieldProps } from '../theme';
import { useRunAfterPaint } from '../hooks/useRunAfterPaint';
import { FEATURE_CODES, useBillingUsage } from '../data/billingUsage';
import { translateVariablesToDateTimeString } from 'odata/queryBuilder';

const useStyles = makeStyles((theme) => ({
  queryUrlContainer: {
    width: '96vw',
    position: 'relative',
    margin: theme.spacing(4, 'auto'),
  },
  queryUrl: {
    maxWidth: `calc(100vw - ${theme.spacing(15)}px)`,
    wordBreak: 'break-all',
    padding: theme.spacing(1, 8, 1, 1),
    textAlign: 'center',
  },
  disableBookmarkBtn: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
  queryActionButtonGroup: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    width: theme.spacing(14),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
}));

const useAdhocQueryPopulation = (importAdhocQuery) => {
  const [isEditQueryDialogOpen, updateIsEditQueryDialogOpen] = useState(false);
  const [queryParserError, updateQueryParserError] = useState();

  const openEditQueryDialog = useCallback((event) => {
    updateIsEditQueryDialogOpen(true);
  }, []);

  const closeEditQueryDialog = useCallback(() => {
    updateQueryParserError('');
    updateIsEditQueryDialogOpen(false);
  }, []);

  const handleAdhocQueryPopulation = useCallback(
    (url) => {
      try {
        importAdhocQuery(url);
        closeEditQueryDialog();
      } catch (error) {
        updateQueryParserError(error?.message ?? 'Failed to parse the query');
      }
    },
    [importAdhocQuery, closeEditQueryDialog]
  );

  return {
    isEditQueryDialogOpen,
    openEditQueryDialog,
    closeEditQueryDialog,
    handleAdhocQueryPopulation,
    queryParserError,
  };
};

const removeNewLines = (text, { start, end }) => {
  let processedString = text.replace(/\n/g, '');

  if (start) {
    processedString = processedString.replace(/^\s{1,}/, '');
  }
  if (end) {
    processedString = processedString.replace(/\s{1,}$/, '');
  }

  return processedString;
};

const preventNewlines = (text, cursor) => {
  const beforeCursor = text.slice(0, cursor);
  const afterCursor = text.slice(cursor, text.length);

  const filteredTextBeforeCursor = removeNewLines(beforeCursor, {
    start: true,
    end: afterCursor.length === 0,
  });
  const filteredTextAfterCursor = removeNewLines(afterCursor, { end: 0 });

  const newText = filteredTextBeforeCursor + filteredTextAfterCursor;
  const newCursor = filteredTextBeforeCursor.length;

  return [newText, newCursor];
};

const dialogTitleId = 'edit-query-title';
const dialogDescriptionId = 'edit-query-description';
function QueryImportDialog({
  queryParserError,
  isEditQueryDialogOpen,
  onSubmit,
  closeEditQueryDialog,
  queryUrl,
  classes,
}) {
  const runAfterPaint = useRunAfterPaint();

  const queryInputCallbackRef = useCallback(
    (element) => {
      // eslint-disable-next-line no-unused-expressions
      element?.setSelectionRange?.(0, queryUrl.length);
    },
    [queryUrl]
  );

  // using the forceUpdate so that on filtering the controlled value
  // we get the runAfterPaint callback to update our cursor even when the filtered value is the same as the previous value
  const [, forceUpdate] = useState({});
  const [url, setUrl] = useState(queryUrl);
  const [dirty, setDirty] = useState(false);

  const isValid = dirty && url && url !== queryUrl;

  const handleChange = (event) => {
    const textarea = event.target;
    const value = textarea.value;

    const [text, cursor] = preventNewlines(value, textarea.selectionStart);

    // Prefer not using this API, since it's unstable and in most cases not needed
    //  we are using this here because all the changes here are for the same render cycle
    unstable_batchedUpdates(() => {
      setUrl(text);
      setDirty(true);
      forceUpdate({});
    });

    runAfterPaint(() => {
      textarea.setSelectionRange(cursor, cursor);
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) {
      onSubmit(url);
      setDirty(false);
    }
  };

  return (
    <Dialog
      open={isEditQueryDialogOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id={dialogTitleId}>Edit Query</DialogTitle>
        <DialogContent id={dialogDescriptionId}>
          <TextField
            {...commonTextFieldProps}
            name="queryUrl"
            multiline
            fullWidth
            autoFocus
            label="Query URL"
            value={url}
            onChange={handleChange}
            helperText={dirty ? ' ' : queryParserError || ' '}
            error={!dirty && Boolean(queryParserError)}
            inputRef={queryInputCallbackRef}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit(event);
              }
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button size="small" onClick={closeEditQueryDialog} variant="outlined">
            Cancel
          </Button>
          <Button
            size="small"
            disabled={!isValid}
            type="submit"
            disableElevation
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const selectedEntitySelector = (state) => state.entity?.value;
function QueryDisplayComponent({
  systemType,
  schema,
  baseEndpoint,
  baseParams,
  openCreateBookmarkDialog,
  importAdhocQuery,
}) {
  const classes = useStyles();

  const params = useParams();

  const queryUrl = useQueryUrl();

  const selectedEntity = useQueryBuilderState(selectedEntitySelector);

  useOnQueryBuilderStateUpdate({
    systemType,
    schema,
    baseEndpoint,
    baseParams,
  });

  const openDialog = () => {
    const { currentStepId } = useTourState.get();
    if (currentStepId === tourSteps['bookmark-icon'].id) {
      tourSteps['bookmark-icon'].next();
    }

    openCreateBookmarkDialog(queryUrl);
  };

  const {
    isEditQueryDialogOpen,
    openEditQueryDialog,
    closeEditQueryDialog,
    handleAdhocQueryPopulation,
    queryParserError,
  } = useAdhocQueryPopulation(importAdhocQuery);

  const { data: bookmarkData } = useQueryBookmarks(params.connectionId);
  const { data: billingUsage, isFeatureQuotaExhausted } = useBillingUsage(
    FEATURE_CODES.BOOKMARK_CREATE_COUNT,
    bookmarkData
  );
  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

  const translatedQueryURL = translateVariablesToDateTimeString(queryUrl);

  const { getShareableUrl } = useShareableUrl();
  const copyToClipboard = useCopy(translatedQueryURL);

  return (
    <>
      <Grid
        item
        component={Card}
        variant="outlined"
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        className={classes.queryUrlContainer}
        data-tour-step={tourSteps['query-display'].id}
      >
        <Grid item component={Typography} variant="overline">
          Query
        </Grid>
        <Grid item className={classes.queryUrl}>
          <Typography
            component={Link}
            href={translatedQueryURL}
            target="_blank"
            rel="noopener noreferrer"
            variant="subtitle1"
            color="textPrimary"
          >
            {decodeURIComponent(queryUrl)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {decodeURIComponent(translatedQueryURL)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.queryActionButtonGroup}
        >
          <Tooltip title="Edit Query">
            <span>
              <IconButton onClick={openEditQueryDialog} size="small">
                <MdModeEdit fontSize={18} />
              </IconButton>
            </span>
          </Tooltip>
          {bookmarkData && checkBookmarkExists(bookmarkData, queryUrl) ? (
            <Tooltip title="Bookmarked">
              <IconButton disableRipple size="small">
                <MdBookmark fontSize={18} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={getTooltipTitleForPlanRelatedFeature({
                hasFeatureUsageLimitReached: isFeatureQuotaExhausted,
                hasPlanExpired,
                defaultTitle: 'Bookmark Query URL',
              })}
            >
              <span>
                <IconButton
                  onClick={openDialog}
                  disabled={
                    queryUrl === formQueryURL(baseEndpoint, baseParams) ||
                    isFeatureQuotaExhausted ||
                    hasPlanExpired
                  }
                  data-tour-step={tourSteps['bookmark-icon'].id}
                  size="small"
                >
                  <MdBookmarkBorder fontSize={18} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Copy Shareable Dataflow Query URL">
            <span className={classes.shareButton}>
              <IconButton
                onClick={() => {
                  copyToClipboard(getShareableUrl(queryUrl));
                }}
                size="small"
                disabled={!selectedEntity}
              >
                <MdShare fontSize={18} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Copy Query URL">
            <span>
              <IconButton onClick={copyToClipboard} size="small" disabled={!selectedEntity}>
                <MdContentCopy fontSize={18} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      {isEditQueryDialogOpen && (
        <QueryImportDialog
          {...{
            queryParserError,
            isEditQueryDialogOpen,
            onSubmit: handleAdhocQueryPopulation,
            closeEditQueryDialog,
            queryUrl,
            classes,
          }}
        />
      )}
    </>
  );
}

export const QueryDisplay = React.memo(QueryDisplayComponent);
