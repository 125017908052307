import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useTenantState } from './user';
import { getAPIBaseURL, getServiceInstance } from 'service';
import { useNotifyError } from 'hooks/useNotifyError';
import { dataflowApiBase } from 'service';

const jobsFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${dataflowApiBase}${endpoint}`);
};

export function useQueryAllJobs(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/job`, tenant?.tenant_id],
    queryFn: jobsFetcher,
    enabled: Boolean(tenant),
    refetchOnMount: tenant && connectionId ? 'always' : false,
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

const createJob = (formData, tenant, connectionId) =>
  getServiceInstance(tenant?.tenant_id).post(
    `${dataflowApiBase}/connection/${connectionId}/job`,
    formData
  );
export const useCreateJobMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ formData, tenant, connectionId }) => createJob(formData, tenant, connectionId),
    {
      onSuccess: (data, { connectionId, tenant }) => {
        queryClient.setQueryData([`/connection/${connectionId}/job`, tenant?.tenant_id], [data]);

        const { job_id } = data;
        const { tenant_id } = tenant;

        const jobStatusEndpoint = `${getAPIBaseURL()}/api${dataflowApiBase}/connection/${connectionId}/job/${job_id}/status?tenantId=${tenant_id}`;
        const source = new EventSource(jobStatusEndpoint, { withCredentials: true });

        const jobStatus = {};
        const updateJob = () => {
          // Update the all jobs cache
          queryClient.setQueryData(
            [`/connection/${connectionId}/job`, tenant?.tenant_id],
            (oldJobs) => {
              // Find the current job index
              const jobIndex = oldJobs.findIndex((job) => job.job_id === job_id);

              // Only update the array if the job is found
              if (jobIndex > -1) {
                return [
                  ...oldJobs.slice(0, jobIndex),
                  {
                    ...oldJobs[jobIndex],
                    ...jobStatus,
                  },
                  ...oldJobs.slice(jobIndex + 1),
                ];
              }

              return oldJobs;
            }
          );
        };

        source.addEventListener('status', (event) => {
          const data = JSON.parse(event.data);
          jobStatus.state = data.status;
        });

        source.addEventListener('progress', (event) => {
          const data = JSON.parse(event.data);
          jobStatus.progress = Math.round(data.progress);
          updateJob();
        });

        // When the connection is closed, re-validate the all jobs cache
        source.addEventListener('connection', (event) => {
          if (event.data === 'close') {
            queryClient.invalidateQueries([`/connection/${connectionId}/job`, tenant?.tenant_id]);
          }
        });
      },
      onSettled: (data, error, { connectionId, tenant }) => {
        queryClient.invalidateQueries([`/connection/${connectionId}/job`, tenant?.tenant_id]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a job',
  });

  return {
    createJobMutation: mutation,
  };
};

/**
 * Reinitializes the EventSource connection for receiving real-time updates.
 *
 * @param {Object} queryClient - The query client object used for managing API queries.
 * @param {string} connectionId - The unique identifier for the connection.
 * @param {string} job_id - The identifier for the job.
 * @param {string} tenant_id - The identifier for the tenant.
 * @returns {void}
 */
export const reinitializeEventSource = ({ queryClient, connectionId, job_id, tenant_id }) => {
  const jobStatusEndpoint = `${getAPIBaseURL()}/api${dataflowApiBase}/connection/${connectionId}/job/${job_id}/status?tenantId=${tenant_id}`;
  const source = new EventSource(jobStatusEndpoint, { withCredentials: true });

  const jobStatus = {};

  const updateJob = () => {
    queryClient.setQueryData([`/connection/${connectionId}/job`, tenant_id], (oldJobs) => {
      // Find the current job index
      const jobIndex = oldJobs.findIndex((job) => job.job_id === job_id);

      // Only update the array if the job is found
      if (jobIndex > -1) {
        return [
          ...oldJobs.slice(0, jobIndex),
          {
            ...oldJobs[jobIndex],
            ...jobStatus,
          },
          ...oldJobs.slice(jobIndex + 1),
        ];
      }

      return oldJobs;
    });
  };

  source.addEventListener('status', (event) => {
    const data = JSON.parse(event.data);
    jobStatus.state = data.status;
  });

  source.addEventListener('progress', (event) => {
    const data = JSON.parse(event.data);
    jobStatus.progress = Math.round(data.progress);
    updateJob();
  });

  // When the connection is closed, re-validate the all jobs cache
  source.addEventListener('connection', (event) => {
    if (event.data === 'close') {
      queryClient.invalidateQueries([`/connection/${connectionId}/job`, tenant_id]);
    }
  });
};
