import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { matchSorter } from 'match-sorter';
import { FixedSizeTree } from 'react-vtree';
import { entries, groupBy } from 'lodash-es';

export const useVirtualTreeStyles = makeStyles((theme) => ({
  subHeaders: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    zIndex: 1,
  },
  subHeaderText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  columns: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  collapseBtn: {
    textTransform: 'none',
  },
  allToggleSwitch: {
    zIndex: 1,
  },
  field: {
    paddingLeft: theme.spacing(3.375),
  },
}));

const checkIfNodeIsOpen = (nestingLevel, treeState, node) => {
  if (nestingLevel === 0) {
    return Boolean(treeState[node.name]);
  }

  return false;
};

const getNodeData = (node, nestingLevel, treeState) => ({
  data: {
    id: node.id.toString(),
    isLeaf: node?.children ? node.children.length === 0 : true,
    isOpenByDefault: checkIfNodeIsOpen(nestingLevel, treeState, node),
    name: node.name,
    nestingLevel,
    property: nestingLevel > 0 ? node.data : {},
  },
  nestingLevel,
  node,
});

// Generator function used by FixedSizeTree component
function* treeWalker(groupedFields, treeState) {
  for (let i = 0; i < groupedFields.length; i++) {
    yield getNodeData(groupedFields[i], 0, treeState);
  }

  while (true) {
    const parentMeta = yield;

    if (parentMeta.node.children) {
      for (let i = 0; i < parentMeta.node.children.length; i++) {
        yield getNodeData(parentMeta.node.children[i], parentMeta.nestingLevel + 1);
      }
    }
  }
}

export function VirtualTree({ groupedFields, itemSize = 40, NodeComponent, ...rest }) {
  const [treeState, setTreeState] = useState(() => ({ [groupedFields[0].name]: true }));
  const toggleTree = (property) => () => {
    setTreeState((state) => ({
      ...state,
      [property]: !state[property],
    }));
  };

  if (groupedFields.length === 0) {
    return null;
  }

  return (
    <FixedSizeTree
      treeWalker={() => treeWalker(groupedFields, treeState)}
      itemSize={itemSize}
      height={300}
      width="100%"
    >
      {(props) => (
        <NodeComponent treeState={treeState} toggleTree={toggleTree} {...props} {...rest} />
      )}
    </FixedSizeTree>
  );
}

// This piece of code moulds the data we are getting (key-value pair format) into a structure that is easy for the virtual tree to consume
const generateVTreeConsumableData = (allFields, groupByIteratee) => {
  const groupedFields = entries(groupBy(allFields, groupByIteratee));

  return groupedFields.reduce((groupedFields, [parentName, children]) => {
    const _children = children.map((child) => ({
      id: child.name,
      name: child.name,
      data: { ...child },
    }));

    groupedFields = [
      ...groupedFields,
      {
        name: parentName,
        id: parentName,
        children: _children,
      },
    ];

    return groupedFields;
  }, []);
};

export const useFilterFields = ({ options, groupByIteratee }) => {
  // Searchbox
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = useCallback((event) => {
    setInputValue(event.currentTarget.value);
  }, []);

  // Filtered Properties
  const [filteredFields, setFilteredFields] = useState(options);
  useEffect(() => {
    const fields = matchSorter(options, inputValue, {
      keys: ['name'],
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    });
    setFilteredFields(fields);
  }, [inputValue, options]);

  //Future Changes: Might require optimization in case there is any performance issue
  //Moulding data to make it consumable for the tree component
  const groupedFields = generateVTreeConsumableData(filteredFields, groupByIteratee);

  return { inputValue, groupedFields, handleInputChange };
};
