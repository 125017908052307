import { STEPPER_FLOWS } from 'components/ReportViewComponents/constants';

export const BATCH_STATE = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  IMPORTING: 'IMPORTING',
  READY: 'READY',
  FETCH_FAILED: 'FETCH_FAILED',
  IMPORT_FAILED: 'IMPORT_FAILED',
};

export const initialWorkerStateMachine = {
  changeId: null,
  noOfBatches: null,
  batches: [],
  recordsFetched: null,
  totalRecords: null,
};

/**
 * Generates a database name prefix for the current tab. This is so that two
 * databases in different tabs do not collide which can cause consistency issues.
 */
export function generateDatabaseNamePrefix({ tabId }) {
  return `__integrtr_dataflow-${tabId}-`;
}

/**
 * Function that parses the query url and returns the entity name from it
 *
 * @param {string} url the query url from which you want to extract the entity
 * @returns {string} the entity name
 */
export function extractEntityFromQueryString(url) {
  const match = url.match(/v2\/([^?]+)/);
  return match ? match[1] : null;
}

export const setStepperDialogPrimaryBtnText = (activeStep, processLength, finalStepBtnText) => {
  const finalStep = processLength - 1;
  if (activeStep === finalStep) {
    return finalStepBtnText;
  }
  return 'Next';
};

export const getStepperSteps = (stepperFlow) => {
  switch (stepperFlow) {
    case STEPPER_FLOWS.CREATE_EXPORT_JOB.KEY:
      return STEPPER_FLOWS.CREATE_EXPORT_JOB.STEPS;

    case STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.KEY:
      return STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.STEPS;

    case STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY:
      return STEPPER_FLOWS.SYSTEM_CONFIGURATION.STEPS;

    default:
      return null;
  }
};

export const checkPasswordStrength = (currentString) => {
  switch (true) {
    case currentString.trim() === '':
      return { isPasswordValid: false, helperText: 'Password Field cannot be empty' }; // Empty string
    case currentString.length < 8:
      return {
        isPasswordValid: false,
        helperText: 'Length of password should be at least 8 characters',
      }; // Length less than 8
    case !/[!@#$%^&*(),.?":{}|<>]/.test(currentString):
      return {
        isPasswordValid: false,
        helperText: 'Should contain at least 1 special character',
      }; // Missing special character
    case !/\d/.test(currentString):
      return {
        isPasswordValid: false,
        helperText: 'Should contain at least 1 number',
      }; // Missing number
    case !/[A-Z]/.test(currentString):
      return {
        isPasswordValid: false,
        helperText: 'Should contain at least 1 uppercase letter',
      }; // Missing uppercase letter
    case !/[a-z]/.test(currentString):
      return {
        isPasswordValid: false,
        helperText: 'Should contain at least 1 lowercase letter',
      }; // Missing lowercase letter
    default:
      return { isPasswordValid: true, helperText: '' }; // Valid string
  }
};
