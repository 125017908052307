import axios from 'axios';
import { nanoid } from 'nanoid';
import Cookies from 'js-cookie';

import { CONFIG } from './config';
import { COOKIES } from './constants';
import { useAuthTokenInjectStore } from './devOnly/authTokenInjectorState';

/**
 * function to get asset urls from CDN
 *
 * @param {string} assetPath
 *
 * @returns {string} fullUrl
 */
export const getAssetURL = (assetPath) => `${CONFIG.CDN_BASEURL}${assetPath}`;

/**
 * function to get the a dash prefixed ENV for selecting the right domain from the hostname
 *
 * @returns {string}
 */
const getDomainEnvFromHostname = () => {
  const subdomain = window.location.hostname.split('.').shift();

  const envWithDashPrefixed = subdomain.replace(CONFIG.APP_NAME, '');

  return envWithDashPrefixed;
};

/**
 * function to get the right env
 *
 * @returns { 'dev' | 'staging' | 'production' | 'demo' | 'predemo' | 'experiment' | 'hotfix' }
 */
export const getAppEnv = () => {
  const envWithDashPrefixed = getDomainEnvFromHostname();

  if (envWithDashPrefixed) {
    return envWithDashPrefixed.slice(1);
  }

  return 'production';
};

/**
 * function to get the Platform URL
 *
 * @return {string}
 */
export const getPlatformURL = () =>
  CONFIG.ENV !== 'production'
    ? CONFIG.LOCAL_PLATFORM_URL
    : `https://platform${getDomainEnvFromHostname()}.integrtr.com`;

/**
 * @function
 *
 * redirect to login page url
 *
 * @param {string} [locationHref]
 */
export const redirectToLoginPage = (locationHref = window.location.href) =>
  window.location.replace(
    `${getPlatformURL()}/login?redirectTo=${encodeURIComponent(locationHref)}`
  );

/**
 * function to get auth token
 *
 */
export const getAuthToken = () => {
  let { AUTH_TOKEN: authToken } = CONFIG;

  let injectedAuthToken = useAuthTokenInjectStore.get();
  if (injectedAuthToken) {
    authToken = injectedAuthToken;
  }

  if (CONFIG.ENV === 'production') {
    authToken = Cookies.get(COOKIES.BASIC_AUTH_TOKEN) ?? Cookies.get(COOKIES.SSO_AUTH_TOKEN);
  }

  return authToken;
};

/**
 * function to get the headers for API calls
 *
 * @param {string} tenantId
 * @param {object} options
 * @param {boolean} options.tenantId
 * @param {string} options.authToken
 *
 * @throws If cookies cannot be found in production ENV then we redirect them to Home
 *
 * @returns {{ 'X-TenantId': string, 'Authorization': string }}
 */
const getHeaders = (tenantId, { isWorker = false, authToken = getAuthToken() } = {}) => {
  if (!authToken && !isWorker) {
    if (CONFIG.ENV === 'production') {
      redirectToLoginPage();
    }
  }

  return {
    Authorization: `Bearer ${authToken}`,
    ...(Boolean(tenantId) && { 'X-TenantId': tenantId }),
  };
};

/**
 * function to get the API base URL based on the ENV values
 *
 * @return {string}
 */
export const getAPIBaseURL = () => {
  if (CONFIG.ENV !== 'production') {
    return CONFIG.API_BASE_URL;
  }

  const env = getDomainEnvFromHostname();

  return `https://platform${env}-api.integrtr.com`;
};

export const systemApiBase = '/app/system';
export const dataflowApiBase = '/app/dataflow';
export const billingApiBase = '/app/billing';
export const mdGenApiBase = '/app/mdgen';

const responseInterceptor = (response) => response.data;
const errorInterceptor = (error) => {
  if (axios.isCancel(error)) {
    throw error;
  }

  let errorResponse = { key: nanoid(10) };
  if (error.response && error.response.data) {
    errorResponse = {
      status: error.response.status,
      ...errorResponse,
      ...(typeof error.response.data === 'object'
        ? { ...error.response.data }
        : {
            errorDetails: error.response.data,
          }),
    };
  }

  throw errorResponse;
};

/**
 * @param {string} [tenantId]
 *
 * @returns [AxiosInstance]
 */
export const getServiceInstance = (tenantId, { isWorker = false, authToken } = {}) => {
  const serviceInstance = axios.create({
    withCredentials: true,
    headers: {
      ...getHeaders(tenantId, { isWorker, authToken }),
      'Content-Type': 'application/json',
    },
    baseURL: `${getAPIBaseURL()}/api`,
  });

  // attach unique error keys to each error so that useNotifyError hook
  // can identify new errors and show them
  serviceInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return serviceInstance;
};

/**
 * Function returns all the users belonging to the provided tenant
 *
 * @param {string} tenantId
 */
export function getUsersByTenantId(tenantId) {
  return getServiceInstance(tenantId).get('/tenant/users');
}
