/**
 * @file
 *
 * this file contains the component where the jobs are shown
 */

import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  Stack,
  Typography,
  LinearProgress,
  Tooltip,
  CircularProgress,
  IconButton,
  Chip,
  Button,
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  BiCheckCircle,
  BiCloudDownload,
  BiCopyAlt,
  BiErrorCircle,
  BiHourglass,
  BiInfoCircle,
  BiRefresh,
  BiTimeFive,
} from 'react-icons/bi';
import { useQueryClient } from 'react-query';

import { mui5Theme } from 'mui5Theme';
import { NoBookmarksImage } from 'components/Illustrations/NoBookmarks';
import { cast, getJobsInProgress } from 'utils';
import { useTenantState } from 'data/user';
import { getAPIBaseURL } from 'service';
import { dataflowApiBase } from 'service';
import { extractEntityFromQueryString } from 'reportView/utils';
import { JOB_STATE, STEPPER_FLOWS } from './constants';
import { ReportViewSkeletonLoader } from './ReportViewLogsPanel';
import { ErrorImage } from 'components/Illustrations/Error';
import { useCopy } from 'hooks/useCopy';
import { useDisclosure } from 'hooks/useDisclosure';
import CommonStepperDialog from './CommonStepperDialog';
import { useCreateExportJob } from 'hooks/useCreateExportJob';
import { reinitializeEventSource } from 'data/jobs';

export default function JobsPanel({
  jobsData,
  isJobsLoading,
  isJobsRefetching,
  refetchJobs,
  jobsError,
  isCreatingJob,
  updateDrawerTabIndex,
  openDrawer,
}) {
  const tenant = useTenantState();
  const params = useParams();
  const queryClient = useQueryClient();

  const [showExpiredJobs, setShowExpiredJobs] = useState(false);
  const [jobs, setJobs] = useState([]);

  const {
    isOpen: isRetriggerJobDialogOpen,
    open: openRetriggerJobDialog,
    close: closeRetriggerJobDialog,
  } = useDisclosure();

  // Refetch list of jobs on mount and re-initialize event source connections
  // for in-progress jobs
  useEffect(() => {
    const fetchAndReinitializeJobs = async () => {
      const allJobs = await refetchJobs();

      const jobsInProgress = getJobsInProgress(allJobs.data);

      if (jobsInProgress.length > 0) {
        jobsInProgress.forEach((job) => {
          reinitializeEventSource({
            queryClient: queryClient,
            connectionId: params.connectionId,
            job_id: job.job_id,
            tenant_id: params.tenantId,
          });
        });
      }
    };

    fetchAndReinitializeJobs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleJobType = useCallback(() => {
    setShowExpiredJobs((prevShowExpiredJobs) => !prevShowExpiredJobs);
  }, [setShowExpiredJobs]);

  const { handleCreateJob, setFilePassword, setJobInfoForRetrigger } = useCreateExportJob(
    openRetriggerJobDialog,
    openDrawer,
    updateDrawerTabIndex,
    true,
    toggleJobType
  );

  useEffect(() => {
    if (!jobsData) {
      return;
    }
    const currentDate = new Date();
    const filteredJobs = jobsData.filter((job) => {
      const expiredDate = new Date(job.expired_at);
      const isJobExpired = job.expired_at ? expiredDate < currentDate : false;
      const isJobCompleted = job.progress === 100;
      // job is considered as expired if expired date is less than current date and the job is completed
      return showExpiredJobs ? isJobExpired && isJobCompleted : !isJobExpired;
    });

    setJobs(filteredJobs);
  }, [jobsData, showExpiredJobs]);

  if (isJobsLoading || isJobsRefetching) {
    return <ReportViewSkeletonLoader />;
  }

  if (jobsError) {
    return (
      <Stack direction="column" alignItems="center" mt={3}>
        <Stack justifyContent="center">
          <ErrorImage width="180px" height="200px" />
        </Stack>
        <Stack justifyContent="center">
          <Typography>{jobsError?.msg ?? 'Failed to fetch list of jobs'}</Typography>
        </Stack>
      </Stack>
    );
  }

  if (jobsData.length === 0) {
    return (
      <Stack height="100%" alignItems="center" mt={10}>
        <NoBookmarksImage width="180px" height="200px" />
        <Typography color="textSecondary">No Jobs to be shown</Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        justifyContent="space-between"
        borderBottom="1px solid lightgray"
        direction="row"
        alignItems="center"
        sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 10 }}
      >
        <Typography ml={2} sx={{ fontWeight: 500 }}>
          {showExpiredJobs ? 'Expired Jobs ' : 'Active Jobs '}({jobs.length})
        </Typography>
        <Button
          variant="text"
          onClick={toggleJobType}
          size="small"
          sx={{ margin: 1, textTransform: 'none' }}
          disableRipple
        >
          {showExpiredJobs ? 'Show Active Jobs' : 'Show Expired Jobs'}
        </Button>
      </Stack>
      {jobs.map((job) => (
        <Job
          key={job.job_id}
          job={job}
          connectionId={params.connectionId}
          tenantId={tenant.tenant_id}
          isCreatingJob={isCreatingJob}
          showExpiredJobs={showExpiredJobs}
          setJobInfoForRetrigger={setJobInfoForRetrigger}
          openRetriggerJobDialog={openRetriggerJobDialog}
        />
      ))}
      <CommonStepperDialog
        isCommonStepperDialogOpen={isRetriggerJobDialogOpen}
        closeCommonStepperDialog={closeRetriggerJobDialog}
        finalActionBtnProps={{
          btnAction: handleCreateJob,
          btnText: 'Create Job',
          btnProps: null,
        }}
        stepperFlowProps={{
          setFilePassword: setFilePassword,
        }}
        stepperFlow={STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.KEY}
        size="sm"
      />
    </Stack>
  );
}

function EachJob({
  job,
  connectionId,
  tenantId,
  isCreatingJob,
  setJobInfoForRetrigger,
  openRetriggerJobDialog,
}) {
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [isRetriggeringJob, setIsRetriggeringJob] = useState(false); // local job export state for each job
  const copyToClipboard = useCopy();

  const {
    job_id,
    completed_at,
    additional_info,
    progress,
    state,
    expired_at,
    error_details,
    file,
    created_at,
  } = job;

  const entityName = extractEntityFromQueryString(additional_info?.query_string);

  const isJobInProgress = state === JOB_STATE.WAITING || state === JOB_STATE.STARTED;

  const currentDate = new Date();
  const expiryDate = new Date(expired_at);
  const isJobExpired = expired_at ? currentDate > expiryDate : false;

  const handleFileDownload = useCallback(async () => {
    setIsFileDownloading(true);

    const fileDownloadUrl = `${getAPIBaseURL()}/api${dataflowApiBase}/connection/${connectionId}/job/${job_id}/file?tenantId=${tenantId}`;
    window.open(fileDownloadUrl, '_blank');

    setIsFileDownloading(false);
  }, [connectionId, job_id, tenantId]);

  useEffect(() => {
    if (!isCreatingJob) {
      setIsRetriggeringJob(false);
    }
  }, [isCreatingJob]);

  const handleJobRetrigger = useCallback(() => {
    setIsRetriggeringJob(true);
    setJobInfoForRetrigger(
      additional_info?.query_string,
      additional_info?.show_picklist_value,
      additional_info?.sheet_name_accessor
    );
    openRetriggerJobDialog();
  }, [additional_info, openRetriggerJobDialog, setJobInfoForRetrigger]);

  return (
    <Stack>
      <Stack borderBottom="1px solid lightgray" spacing={1} p={1.5}>
        <Stack direction="column">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1} width="73%">
              <Box sx={{ display: 'flex' }}>{getIconByState(state, isJobExpired)}</Box>
              <Tooltip title={entityName}>
                <Typography noWrap>
                  Data Export: <strong>{entityName}</strong>
                </Typography>
              </Tooltip>
              <Tooltip title="Copy Query URL">
                <IconButton
                  size="small"
                  sx={{ borderRadius: 1, color: mui5Theme.palette.grey[500] }}
                  onClick={() => copyToClipboard(additional_info?.query_string)}
                >
                  <BiCopyAlt size={16} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {isFileDownloading ? (
                <CircularProgress size={18} />
              ) : (
                !isJobExpired &&
                state === JOB_STATE.COMPLETED && (
                  <Tooltip title={file ? 'Download File' : 'File not found'}>
                    <span>
                      <IconButton
                        size="small"
                        sx={{ borderRadius: 1 }}
                        onClick={handleFileDownload}
                        disabled={!file}
                        color="primary"
                      >
                        <BiCloudDownload size={18} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              )}
              {(isJobExpired || state === JOB_STATE.FAILED || state === JOB_STATE.ABORTED) && (
                <Tooltip title="Re-trigger Job">
                  <IconButton
                    size="small"
                    sx={{ borderRadius: 1 }}
                    onClick={handleJobRetrigger}
                    color="primary"
                  >
                    {isRetriggeringJob ? <CircularProgress size={18} /> : <BiRefresh size={18} />}
                  </IconButton>
                </Tooltip>
              )}
              {getInfoIcon(state, isJobExpired, expired_at, completed_at, error_details)}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
            <Typography variant="body2" fontStyle="italic" color="GrayText">
              Created on {cast.date(created_at, true).format('DD MMM YYYY HH:mm:ss')}
            </Typography>
            {isJobInProgress && (
              <Typography variant="body2" color={mui5Theme.palette.warning.main}>
                {progress}%
              </Typography>
            )}
            {isJobExpired && <Chip size="small" label="Expired" sx={{ borderRadius: 1.3 }} />}
          </Stack>
        </Stack>
      </Stack>
      {isJobInProgress && <LinearProgress variant="determinate" value={progress} color="warning" />}
    </Stack>
  );
}

const Job = memo(EachJob);

/**
 * Function that returns relevant icons based on the job state
 *
 * @param {string} jobState
 * @returns
 */
const getIconByState = (jobState, isJobExpired) => {
  if (isJobExpired) {
    return <BiTimeFive size={18} style={{ color: mui5Theme.palette.grey[600] }} />;
  }

  if (jobState === JOB_STATE.COMPLETED) {
    return <BiCheckCircle size={18} style={{ color: mui5Theme.palette.success.main }} />;
  }

  if (jobState === JOB_STATE.FAILED || jobState === JOB_STATE.ABORTED) {
    return <BiErrorCircle size={18} style={{ color: mui5Theme.palette.error.main }} />;
  }

  return <BiHourglass size={18} style={{ color: mui5Theme.palette.warning.main }} />;
};

/**
 * Function that returns the relevant info icon based on the job state
 *
 * @param {string} state the state of the job
 * @param {boolean} isJobExpired whether the job was expired or not
 * @param {string} expired_at datetime when the job expired
 * @param {string} completed_at datetime when the job was completed
 * @param {string} error_details information related to the error
 */
function getInfoIcon(state, isJobExpired, expired_at, completed_at, error_details) {
  if (isJobExpired && expired_at) {
    return (
      <Tooltip
        title={`The file expired on ${cast.date(expired_at, true).format('DD MMM YYYY HH:mm:ss')}`}
      >
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BiInfoCircle size={15} style={{ color: mui5Theme.palette.grey[600] }} />
        </span>
      </Tooltip>
    );
  }

  if (state === JOB_STATE.COMPLETED && completed_at) {
    return (
      <Tooltip
        title={`Job completed at ${cast.date(completed_at, true).format('DD MMM YYYY HH:mm:ss')}`}
      >
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BiInfoCircle size={15} style={{ color: mui5Theme.palette.success.main }} />
        </span>
      </Tooltip>
    );
  }

  if (state === JOB_STATE.FAILED || state === JOB_STATE.ABORTED) {
    return (
      <Tooltip title={error_details ?? 'Something went wrong. Please run the job again'}>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BiInfoCircle size={15} style={{ color: mui5Theme.palette.error.main }} />
        </span>
      </Tooltip>
    );
  }
}
